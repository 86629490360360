<template>
  <div v-loading="loading" style="margin-left: 20px; height: calc(100vh - 130px)">
    <el-row :gutter="20">
      <img :src="auditStatePic" class="auditPic" v-if="auditStatePic" />
      <el-col :span="24" class="titleContent">
        <span class="title iconTitle"><i></i>基础信息</span>
      </el-col>
      <el-col :span="24" class="item">
        <span class="title subTitle">{{ info.name }}</span>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="6" class="item">
        <span>所属公司：</span>
        {{ info.companyName }}
      </el-col>
      <el-col :span="9" class="item">
        <span>所属车队：</span>
        {{ info.motorcadeName }}
      </el-col>
      <el-col :span="4" class="item">
        <span>所属车辆：</span>
        {{ info.carNumber }}
      </el-col>
      <el-col :span="5" v-if="/^(http|https):\/\/[^\s]*.(jpg|png|jpeg|bmp)+$/.test(info.imgPath)">
        <div class="thumbnail-box">
          <div class="thumbnail">
            <el-image :src="info.imgPath" fit="fill"></el-image>
            <el-button type="text" style="color:#2C6AFF" @click="showImageDialog = true">查看驾驶证</el-button>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="6" class="item">
        <span>身份证号码：</span>
        {{ info.idCard }}
      </el-col>
      <el-col :span="9" class="item">
        <span>籍贯：</span>
        {{ info.nativePlace }}
      </el-col>
      <el-col :span="4" class="item">
        <span>有效期至：</span>
        {{ dateFormatType(info.validateTime) }}
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="6" class="item">
        <span>出生日期：</span>
        {{ dateFormatType(info.birthday) }}
      </el-col>
      <el-col :span="9" class="item">
        <span>初次领证日期：</span>
        {{ dateFormatType(info.firstApplyTime) }}
      </el-col>
      <el-col :span="4" class="item">
        <span>准驾车型：</span>
        {{ info.carType }}
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :span="6" class="item">
        <span>手机号：</span>
        {{ info.mobile }}
      </el-col>
      <el-col :span="13" class="item">
        <span>地址：</span>
        {{ info.provinceName + info.cityName + info.distinctName + info.address }}
      </el-col>
    </el-row>
    <div v-if="account.cardName != null">
      <el-row :gutter="20">
        <el-col :span="24" class="titleContent">
          <span class="title iconTitle"><i></i>开户信息</span>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="24" class="item">
          <span class="title subTitle">银行卡信息</span>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="6" class="item">
          <span>银行卡户名:</span>{{ account.cardName }}
        </el-col>
        <el-col :span="9" class="item">
          <span>开户行:</span>{{ account.bankName }}
        </el-col>
        <el-col :span="4" class="item">
          <span>银行卡号:</span>{{ account.cardNo }}
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="6" class="item">
          <span>绑定手机号:</span>{{ account.mp }}
        </el-col>
        <el-col :span="18" class="item">
          <span>开户行所在地:</span>{{ account.cardProvName + "/" + account.cardAreaName }}
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="24" class="item">
          <span class="title subTitle">身份证信息</span>
        </el-col>
        <el-col :span="6" class="item">
          <span>签发日期:</span>{{ info.certBeginDate }}
        </el-col>
        <el-col :span="9" class="item">
          <span>失效日期:</span>{{ info.certValidityType == 1 ? "长期" : info.certEndDate }}
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="24" class="item">
          <span class="title subTitle" style="width:auto;padding-right:10px;">申请结果</span>
          <el-link class="link-record" type="primary" @click="showRecordDialog = true" :underline="false"><i
              class="el-icon-tickets"></i>开户审核记录</el-link>
        </el-col>
        <el-col :span="6" class="item">
          <span>商户号:</span>{{ info.huifuId }}
        </el-col>
        <el-col :span="9" class="item">
          <span>取现卡序列号:</span>{{ account.huifuTokenNo }}
        </el-col>
      </el-row>
    </div>
    <div>
      <el-row :gutter="20">
        <el-col :span="24" class="titleContent">
          <span class="title iconTitle"><i></i>操作记录</span>
        </el-col>
        <!-- <el-col :span="24" class="item">
          <span>审核结果:</span>{{ info.accountApplyStatusName }}
        </el-col>
        <el-col :span="24" v-if="info.accountApplyStatus == 1" class="item">
          <span>不通过原因:</span>{{ info.reason }}
        </el-col> -->
        <el-col :span="24">
          <table-compnent :records="auditRecords" :showDelete="false" :showAdd="false" :showSearch="false"
            :showReset="false" :loading="false">
            <template v-slot:content>
              <el-table-column prop="opUserName" label="操作人"></el-table-column>
              <el-table-column prop="typeName" label="操作"></el-table-column>
              <el-table-column prop="opTimeStr" label="操作时间"></el-table-column>
              <el-table-column prop="reason" label="备注"></el-table-column>
            </template>
          </table-compnent>
        </el-col>

      </el-row>
    </div>
    <div class="auditDriver" v-if="info.accountApplyStatus == 2 && !(isCustomer || idTraffic_bureau)">
      <el-button type="primary" @click="auditDriver">审核</el-button>
    </div>
    <el-dialog title="查看图片" :visible.sync="showImageDialog" width="50%" :close-on-click-modal="false"
      @closed="showImageDialog = false">
      <img :src="info.imgPath" width="100%" />
    </el-dialog>
    <el-dialog title="开户审核记录" :visible.sync="showRecordDialog" width="50%" :close-on-click-modal="false"
      @closed="showRecordDialog = false">
      <table-compnent :records="recordList" v-show="showRecordDialog" @searchHandler="getRecordList" :showDelete="false"
        :showAdd="false" :showSearch="false" :showReset="false" :loading="false">
        <template v-slot:content>
          <el-table-column prop="reqSeqId" label="请求流水号"></el-table-column>
          <el-table-column prop="auditStatusName" label="审核状态"></el-table-column>
          <el-table-column prop="createTimeStr" label="创建时间"></el-table-column>
          <el-table-column prop="auditDesc" label="审核意见"></el-table-column>
        </template>
      </table-compnent>
    </el-dialog>
    <el-dialog title="司机审核" width="600px" :visible.sync="showAuditDialog" :close-on-click-modal="false"
      @closed="showAuditDialog = false">
      <el-form v-model="audit" label-width="100px">
        <el-form-item label="司机姓名">
          {{ audit.userName }}
        </el-form-item>
        <el-form-item label="审核结果">
          <el-radio-group v-model="audit.isPass">
            <el-radio :label="true">审核通过</el-radio>
            <el-radio :label="false">审核不通过</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="不通过原因" required v-if="!audit.isPass">
          <el-input type="textarea" :rows="4" style="width: calc(100% - 50px)" v-model="audit.reason" maxlength="50"
            show-word-limit>
          </el-input>
        </el-form-item>
        <div class="operate">
          <el-button type="primary" @click="submitAudit">提交</el-button>
          <el-button @click="showAuditDialog = false">取消</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script type="text/babel">
import TripDriverApi from "@/api/TripDriverApi";
import listPage from "../../base/listPage.vue";
export default {
  extends: listPage,
  data() {
    return {
      info: {},
      data: {},
      account: {},
      imageView: [],
      options: [],
      recordList: {},
      showImageDialog: false,
      showRecordDialog: false,
      showAuditDialog: false,
      auditStatePic: null,
      auditRecords: { records: [] },
      auditStateImages: [
        require("@/assets/img/auditState/state1.png"),
        require("@/assets/img/auditState/state2.png"),
        require("@/assets/img/auditState/state3.png"),
        require("@/assets/img/auditState/state4.png"),
        require("@/assets/img/auditState/state5.png"),
      ],
      search: {
        name: "",
        carNumber: "",
      },
      audit: {
        id: null,
        userName: "",
        isPass: true,
        reason: "",
      },
    };
  },
  watch: {
    $route() {
      if (this.$route.params.no != null) {
        this.getDetail();
      }
    },
  },
  methods: {
    getDetail() {
      this.loading = true;
      TripDriverApi.queryDriverByNo({ no: this.$route.params.no }).then(
        (resp) => {
          if (resp.code == "200") {
            this.info = resp.data;
            this.auditStatePic =
              this.auditStateImages[this.info.accountApplyStatus - 1];
            this.loadAuditRecords();
            this.getAccount();
            this.loading = false;
          }
        }
      );
    },
    getAccount() {
      TripDriverApi.findDriverAccount(this.info.no).then((resp) => {
        if (resp.code == "200") {
          this.account = resp.data;
        }
      });
    },
    getRecordList(params) {
      let obj = {
        driverNo: this.info.no,
        pageSize: params.pageSize,
        pageNum: params.pageNum,
      };
      TripDriverApi.findDriverAccountApplyList(obj).then((resp) => {
        if (resp.code == "200") {
          this.recordList = resp.data;
        }
      });
    },
    auditDriver() {
      this.audit.id = this.info.id;
      this.audit = {
        id: this.info.id,
        userName: this.info.name,
        isPass: true,
        reason: "",
      };
      this.showAuditDialog = true;
    },
    submitAudit() {
      if (!this.audit.isPass && !this.audit.reason) {
        this.$errorMsg("不通过原因不能为空");
        return;
      }
      TripDriverApi.auditDriver(this.audit)
        .then((res) => {
          if (res.code == "200") {
            this.$successMsg("审核成功");
            this.showAuditDialog = false;
            if (res.data.isPass) {
              this.info.accountApplyStatusName = "审批通过";
              this.info.accountApplyStatus = 3;
              this.account.huifuId = res.data.huifuId;
            } else {
              this.info.accountApplyStatusName = "审批不通过";
              this.info.accountApplyStatus = 1;
            }
            this.info.reason = res.data.reason;
            this.auditStatePic =
              this.auditStateImages[this.info.accountApplyStatus - 1];
          } else {
            this.$errorMsg(res.msg);
          }
        })
        .catch((err) => {
          this.$errorMsg(err);
        });
    },
    loadAuditRecords() {
      TripDriverApi.findDriverOperationRecordList({ driverNo: this.info.no })
        .then((res) => {
          if (res.code == "200") {
            this.auditRecords.records = res.data;
          } else {
            this.$errorMsg(res.msg);
          }
        })
        .catch((err) => {
          this.$errorMsg(err);
        });

    }
  },
  async created() {
    this.getDetail();
  },
};
</script>

<style lang="scss" scoped>
.item {
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 14px;
  display: flex;
  color: #4f4d46;

  span {
    // display: inline-block;
    width: 100px;
    text-align: left;
    color: #666666;
    vertical-align: top;
  }
}

.titleContent {
  font-size: 16px;
  line-height: 32px;
  height: 32px;
  margin-top: 16px;

  .iconTitle {
    height: 32px;
    line-height: 32px;
    color: #4f4d46;
    font-weight: bold;
  }

  i {
    display: inline-block;
    width: 4px;
    height: 16px;
    background: #2c6aff;
    margin-right: 8px;
    margin-top: 5px;
  }
}

::v-deep .el-row {
  line-height: 12px;
}

::v-deep .titleContent .el-button {
  float: right;
  height: 40px;

  span {
    color: white;
  }
}

.img {
  width: 100px;
  height: 100px;
}

.thumbnail-box {
  width: 300px;
  display: flex;
  position: absolute;
  justify-content: baseline;
}

.thumbnail {
  display: block;
  margin: 0 10px;
  z-index: 100;
  width: 50%;
  line-height: 20px;
}

.subTitle {
  font-size: 14px;
  width: auto !important;
  color: #4f4d46 !important;
  font-weight: bold;
  text-align: left;
  margin-top: 8px;
}

::v-deep .thumbnail .el-button {
  width: 100%;
  line-height: 30px;
  padding: 0;
}

::v-deep .thumbnail .el-image {
  border-radius: 4px;
  width: 100%;
  height: 100px;
}

// ::v-deep .el-col {
//   color: #999;
//   span {
//     color: #000;
//     padding-right: 4px;
//   }
// }
::v-deep .el-link--inner {
  padding: 0 0 0 6px;
  color: #2C6AFF !important;

  i {
    margin-right: 4px;
  }
}

::v-deep .el-dialog__body {
  border-top: 1px #e9e9e9 solid;
  padding-bottom: 10px;
}

::v-deep .operate {
  width: 100%;
  margin: 0px 0 10px -20px;
  padding: 0 20px;
  display: flex;
  justify-content: center;

  .el-button {
    height: 40px;
    margin-top: 20px;
  }
}

::v-deep .auditDriver {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 16px 0;

  .el-button {
    width: 92px;
    height: 40px;
    background: #2c6aff;
  }
}

::v-deep .el-dialog__header {
  font-size: 18px;
  font-weight: bold;
  color: #4f4d46;
}

.auditPic {
  position: absolute;
  right: 32px;
  top: 0px;
  width: 80px;
  height: 80px;
}

.link-record {
  line-height: 30px;
}
</style>
            